import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class MembershipService {
    private changeMembreship$ = new Subject();
    private urlService = environment.backURL;

    constructor(
        private http: HttpClient
    ) { }

    public get getChangeMembership() {
        return this.changeMembreship$;
    }

    public set changeMembership(value) {
        this.changeMembreship$.next(value);
    }
    
    public get id() {
        return localStorage.getItem('id');
    }

    public get name() {
        return localStorage.getItem('name');
    }

    public get newUser() {
        return localStorage.getItem('newUser');
    }

    public get email() {
        return localStorage.getItem('email');
    }

    public get type() {
        return localStorage.getItem('type');
    }

    public setName(name) {
        localStorage.setItem('name', name);
    }

    public setEmail(email) {
        localStorage.setItem('email', email);
    }

    public setType(type) {
        localStorage.setItem('type', type);
    }

    public setNewUser(value) {
        localStorage.setItem('newUser', value);
    }

    public setId(id) {
        localStorage.setItem('id', id);
    }

    public clearAll() {
        localStorage.clear();
    }

    public createClient(client) {
        const url = this.urlService + '/payments/create-client';
        return this.http.post(url, client).toPromise();
    }

    public addCardToClient(request) {
        const url = this.urlService + '/payments/add-card';
        return this.http.post(url, request).toPromise();
    }

    public getClientCards(paymentsID) {
        const url = this.urlService + '/payments/get-card-list/' + paymentsID;
        return this.http.get(url).toPromise();
    }

    public getSubsInfo(subsID, paymentsID) {
        const  url = this.urlService + '/payments/get-subscription/' + paymentsID + '/' + subsID;
        return this.http.get(url).toPromise();
    }

    public deleteCard(request) {
        const url = this.urlService + '/payments/delete-card/' + request.clientId + '/' + request.cardId;
        return this.http.delete(url,request).toPromise();
    }

    public sendEmail(email) {
        const url = this.urlService + '/email/send-email';
        const body = {email: email};
        return this.http.post(url, body).toPromise();

    }

    public cancelSubs(paymentsID, subsID) {
        const url = this.urlService + '/payments/cancel-subscription/' + paymentsID + '/' + subsID;
        return this.http.delete(url).toPromise();
    }

    public changeDefaultPayment(paymentsID, request) {
        const url = this.urlService + '/payments/update-payment/' + paymentsID;
        return this.http.put(url, request).toPromise();
    }

    public getPaymentList(paymentsID, request) {
        const url = this.urlService + '/payments/payouts-list/' + paymentsID;
        return this.http.get(url, { params: request }).toPromise();
    }

    public activateSubs(body) {
        const url = this.urlService +'payments/activate-subs';
        return this.http.post(url, body).toPromise();
    }

}
