import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import { AngularFireStorage } from 'angularfire2/storage';
import { HttpClient } from '@angular/common/http';
import { AUCTIONS_PER_PAGE } from 'src/app/data/data';
import { map, concatMap, toArray, filter } from 'rxjs/operators';
import { AuctionModel } from 'src/app/models/auction.model';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
    providedIn: 'root'
})
export class AuctionService {
    private urlService = environment.backURL + '/auctions?';
    private search$ = new Subject<string>();
    public totalURL = environment.totalAuctions;
    constructor(
        public db: AngularFirestore,
        private storage: AngularFireStorage,
        private http: HttpClient
    ) { }

    public get search() {
        return this.search$;
    }

    public set setSearch(value) {
        this.search$.next(value);
    }

    public getAuctionById(auctionID) {
        const id = auctionID.toString();

        return this.db.collection('auctions').doc(id).valueChanges();
    }

    public setAuctionViews(auctionID, views) {
        return this.db.collection('auctions').doc(auctionID).update({ views: views });
    }

    public getURL(url: string) {
        const tempArray = (url.split('@')[1]).split(',');
        const lat = Number(tempArray[0]);
        const lng = Number(tempArray[1]);
        return ({ latitude: lat, longitude: lng });
    }
    public getAuctions(elementsNumber, filters?) {
        return this.db.collection('auctions', ref => ref.where('appraisalPrice', '>', 600000).orderBy('appraisalPrice').limit(elementsNumber)).get().pipe(
            concatMap(x => x.docs),
            map((data: any) => {
                const model = new AuctionModel(data.id, data.data());
                return { ...model };
            }),
            toArray()
        );
    }
    public getTotalAuctions() {
        return this.http.get(this.totalURL);
    }

    public getAuctionsService(page, filters?) {
        const pag = 'page=' + page;
        let filterURL = '';
        if (filters) {
            if (filters.minPrice) {
                filterURL += '&minPrice=' + filters.minPrice;
            }
            if (filters.maxPrice) {
                if (filters.maxPrice === 600000) {
                    filterURL += '&maxPrice=' + 100000000;
                }
                filterURL += '&maxPrice=' + filters.maxPrice;
            }
            if (filters.state) {
                const stateString = filters.state.toLowerCase().split(' ');
                filterURL += '&state=' + stateString[0];
            }
            if (filters.borough) {
                filterURL += '&city=' + filters.borough.toLowerCase();
            }
            if (filters.propertyType) {
                filterURL = '&type=' + filters.propertyType;
            }
            if (filters.totalArea) {
                filterURL = '&area=' + filters.totalArea;
            }
        } else {
            filterURL += '&minPrice=' + 600000;
            filterURL += '&maxPrice=' + 100000000;
        }

        return this.http.get(this.urlService + pag + filterURL);
    }

    public getSearchAuctions(search: string) {
        const arrSearch = search.split(' ');
        return this.db.collection('auctions', ref => ref.where('appraisalPrice', '>=', 600000)).get().pipe(
            concatMap(x => x.docs),
            filter((auction: any) => {
                const value = arrSearch.filter(param => {
                    if (param === '') {
                        return false;
                    }
                    return (Object.values(auction.data()).toString().toLowerCase().includes(param.toLowerCase())
                        || Object.values(auction.data().address).toString().toLowerCase().includes(param.toLowerCase())
                        || Object.values(auction.data().auctionDetails).toString().toLowerCase().includes(param.toLowerCase())
                        || Object.values(auction.data().details).toString().toLowerCase().includes(param.toLowerCase()));
                });
                return (value.length > 0);
            }),
            map((data: any) => {
                const model = new AuctionModel(data.id, data.data());
                return { ...model };
            }),
            toArray()
        );
    }

    public getMainImage(auctionID) {
        return this.storage.ref(auctionID + '/PropertyImages/imagePrincipal').getDownloadURL();
    }

    public getDocumentURL(auctionID, name) {
        return this.storage.ref(auctionID + '/Documents/' + name).getDownloadURL().toPromise();
    }

    public getTestimonies() {
        return this.db.collection('testimony').doc('testimony').get().pipe(
            map(data => {
                return data.data();
            }));
    }

}

