<div id="ss-terms-faqs" class="ss-color-blue-grey ss-terms-faqs">
    <p class="ss-heebo ss-bold ss-terms-faqs-title">{{title}}</p>
    <div class="ss-terms-faqs-row">
        <div class="ss-terms-faqs-select">
            <div class="ss-size-14 ss-terms-select ss-bg-white ss-open-sans">
                <div [class.ss-bg-trans-orange]="isTermsInfo" [routerLink]="['/terms']">
                    <p>Terminos</p>                  
                </div>
                <div [class.ss-bg-trans-orange]="isPrivacy" [routerLink]="['/privacy']">
                    <p>Privacidad</p>                  
                </div>
                <div [class.ss-bg-trans-orange]="isFaq" [routerLink]="['/faqs']">
                    <p>{{'TERMS.faqs' | translate}}</p>
                </div>
            </div>
        </div>
        <div class="ss-terms-faqs-content">
            <app-terms *ngIf="isTermsInfo"></app-terms>
            <app-privacy *ngIf="isPrivacy"></app-privacy>
            <app-faqs *ngIf="isFaq"></app-faqs>
        </div>
    </div>
</div>