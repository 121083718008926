import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SNACKBAR_OPTIONS, SNACKBAR_POSITION } from 'src/app/data/data';
@Injectable({
    providedIn: 'root'
})
export class ToastService {
    constructor(private snackBar: MatSnackBar) {}

    public show(message: string) {
        this.snackBar.open(message, null, {
            panelClass: SNACKBAR_OPTIONS.panelClass,
            verticalPosition: SNACKBAR_POSITION,
            duration: SNACKBAR_OPTIONS.duration
        });
    }
}

