// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  gmApiKey: 'AIzaSyBXudyTCBEQqswh1YmIz6U1Qt7hnSUttfE',
  totalAuctions: 'https://subastas-inmobiliarias.firebaseio.com/auctions/total.json',
  backURL:'https://us-central1-subastas-inmobiliarias.cloudfunctions.net/api',
  firebase: {
    apiKey: 'AIzaSyBoIv_NzDMRcUQY_4YjLeClALPJJZ7jFFY',
    authDomain: 'subastas-inmobiliarias.firebaseapp.com',
    databaseURL: 'https://subastas-inmobiliarias.firebaseio.com',
    projectId: 'subastas-inmobiliarias',
    storageBucket: 'subastas-inmobiliarias.appspot.com',
    messagingSenderId: '388628069232',
    appId: '1:388628069232:web:5645c70cf28974f7'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
