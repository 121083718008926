import { Component, ViewChild, ElementRef, OnInit, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DEFAULT_LANGUAGE } from './data/data';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ScrollService } from './services/scroll.service';
import { SubSink } from 'subsink';

declare let fbq: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  @ViewChild('ssMainContent') private myScrollContainer: ElementRef;
  title = 'Subastas';
  private subs = new SubSink();
  public isOportunities = true;
  public currentRoute: any;
  public position = 0;
  public currentState = 'initial';
  constructor(
    private scroll: ScrollService,
    private translate: TranslateService,
    private router: Router,
    private location: Location,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {

    this.subs.add(
      router.events.subscribe((y: NavigationEnd) => {
        if (y instanceof NavigationEnd) {
          fbq('track', 'PageView');
        }
      }),
      router.events.subscribe(() => {
        this.isOportunities = (location.path().includes('oportunities'));
        if (!this.isOportunities) {
          localStorage.removeItem('filterObj');
          localStorage.removeItem('filterForm');
          localStorage.removeItem('filterSearch');
        }
      })
    )


    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang(DEFAULT_LANGUAGE);
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use(DEFAULT_LANGUAGE);
    this.iconRegistry
      .addSvgIcon('ic-arrow-back', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ico-arrow-back.svg'))
      .addSvgIcon('ic-fb', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/redes-facebook.svg'))
      .addSvgIcon('ic-google', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/redes-google.svg'))
      .addSvgIcon('ic-twt', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/redes-twitter.svg'))
      .addSvgIcon('ic-pass-un-on', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/pass-open-look-activo.svg'))
      .addSvgIcon('ic-pass-lock-off', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/pass-look-inactivo.svg'))
      .addSvgIcon('ic-edit', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/edit.svg'))
      .addSvgIcon('ic-diamond', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/diamond.svg'))
      .addSvgIcon('ic-crown', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/crown.svg'))
      .addSvgIcon('ic-pagos', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/pagos-off.svg'))
      .addSvgIcon('ic-metodo-pago', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/metodo-pago-off.svg'))
      .addSvgIcon('ic-flag', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/flag-off.svg'))
      .addSvgIcon('ic-search', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ico-search-active.svg'))
      .addSvgIcon('ic-arrow-left', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/arrow-left.svg'))
      .addSvgIcon('ic-search-close', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/search-close.svg'))
      .addSvgIcon('ic-play', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-ico-play.svg'))
      .addSvgIcon('ic-help', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/help.svg'))
      .addSvgIcon('ic-linkedin', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/linkedin.svg'))
      .addSvgIcon('ic-twitter', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/twitter.svg'))
      .addSvgIcon('ic-facebook', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/facebook.svg'))
      .addSvgIcon('ic-views', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-vistas.svg'))
      .addSvgIcon('ic-arrow-right', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-arrow-right.svg'))
      .addSvgIcon('ic-arrow-left', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-arrow-left.svg'))
      .addSvgIcon('ic-star', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/star-on.svg'))
      .addSvgIcon('ic-close', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-close-circle.svg'));

  }

  ngOnInit() {
    this.scroll.getScroll.subscribe(value => {
      this.scrollToTop();
    });
  }
  onActivate() {
    this.scrollToTop();
  }

  scrollToTop() {
    setTimeout(() => {
      const el: HTMLDivElement = this.myScrollContainer.nativeElement;
      el.scrollTop = Math.max(0, 0);
    }, 10);
  }

  showButton($event: Event) {
    const el: HTMLDivElement = this.myScrollContainer.nativeElement;
    this.position = el.scrollTop;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
