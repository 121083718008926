import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { PROPERTY_TYPE } from 'src/app/data/data';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuctionService } from 'src/app/services/auction.service';
import { take } from 'rxjs/operators';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  @Input() type: any;
  @Input() auction: any;
  public propertyClass = '';
  public image = 'assets/imgs/no-image.svg';
  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private auctionService: AuctionService
  ) { }

  ngOnInit() {
    this.getAuctionInfo();
  }

  public async getAuctionInfo() {
    if (typeof (this.auction.address) !== 'string') {
      this.auction.address = this.auction.address.borough + ', ' + this.auction.address.state;
    }

    this.image = await this.auction.imagePrincipal;
    switch (this.auction.propertyType) {
      case (PROPERTY_TYPE.DEVELOPMENT): {
        this.propertyClass = 'ss-bg-develop';
        break;
      }
      case (PROPERTY_TYPE.LAND): {
        this.propertyClass = 'ss-bg-land';
        break;
      }
      case (PROPERTY_TYPE.OFFICE): {
        this.propertyClass = 'ss-bg-office';
        break;
      }
      case (PROPERTY_TYPE.HOUSE): {
        this.propertyClass = 'ss-bg-house';
        break;
      }
      case (PROPERTY_TYPE.FLAT): {
        this.propertyClass = 'ss-bg-flat';
        break;
      }
      default: {
        this.propertyClass = 'ss-bg-flat';
        break;
      }
    }
  }

  goToAuctionDetails() {
    this.spinner.show();
    this.router.navigate(['oportunities/details/' + this.auction.id + '/' + this.auction.views]);
  }
}
