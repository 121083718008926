import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import { AdditionalInformationService } from '../services/additional-information.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
  public terms;
  @ViewChild('termsMain', { static: true }) private myScrollContainer: ElementRef;
  // @Input() section$ = new Subject<string>();
  constructor(
    private additionalInformationService: AdditionalInformationService,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {
    this.additionalInformationService.getTerms('terms').subscribe(resp => {
      this.terms = this.sanitizer.bypassSecurityTrustHtml(resp.terms);
    });
  }

}
