import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import { AdditionalInformationService } from '../services/additional-information.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {
  public privacy;

  constructor(
    private additionalInformationService: AdditionalInformationService
  ) { }

  ngOnInit() {
    this.additionalInformationService.getAdditionalInformation('privacy').subscribe(resp => {
      this.privacy = resp;
    })
  }

}

