import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class ScrollService {
    private scroll$: Subject<any> = new Subject<any>();
    constructor() { }
    
    public get getScroll() {
        return this.scroll$;
    }

    public set scroll(value) {
        this.scroll$.next(value);
    }
    }

