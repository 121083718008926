import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import { AngularFireStorage } from 'angularfire2/storage';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FaqService {
  constructor(
    public db: AngularFirestore,
    private storage: AngularFireStorage,
  ) { }

  public getFaqs() {
    return this.db.collection('faq').doc('faq').get().pipe(
      map(data => {
        return data.data().faq;
      })
    );
  }
}
