import { Pipe, PipeTransform } from '@angular/core';
import { chunk } from 'lodash';
@Pipe({
    name: 'cardPipe'
})

export class CardPipe implements PipeTransform {
    public language: any;
    constructor() { }

    transform(value: any, args?: any): any {
        value = value.replace(/-/g,'')
        let arrDigits = chunk(value,4);
        arrDigits = arrDigits.map( a =>  a.join(''));
        return arrDigits.join('-');
    }
}
