import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SubSink } from 'subsink';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NAME_REGEX, EMAIL_REGEX } from 'src/app/data/data';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from 'src/app/services/user.service';
import { MailsService } from 'src/app/services/mails.service';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {
  private subs = new SubSink();
  public dataForm: FormGroup;
  public body: any;

  constructor(
    public dialogRef: MatDialogRef<ContactFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private userService: UserService,
    private mailService: MailsService,

  ) { }

  ngOnInit() {
    this.dataForm = this.fb.group({
      completeName: [this.data.name, [Validators.required, Validators.pattern(NAME_REGEX)]],
      email: [this.data.email, [Validators.required, Validators.pattern(EMAIL_REGEX)]],
      message: ['', [Validators.required]]
    });
  }

  sendInfo() {
    const form = this.dataForm.controls;
    const info = {
      name: form.completeName.value,
      email: form.email.value,
      message: form.message.value
    };
    this.requestSendInfo(info);
  }

  async requestSendInfo(data) {
    this.spinner.show();
    data.type = this.data.type;
    data.date = new Date();
    this.body = data;
    try {
      await this.userService.addContactRequest(data);
      this.spinner.hide();
      this.close();
    } catch (err) {
      this.spinner.hide();
      this.close();
    }
  }

  async close() {    
    const form = this.dataForm.controls;
    let resp = await this.mailService.rejectedMembership({
      email: this.body.email,
      name: this.body.name,
      phone: this.body.phone,
      date: this.body.date.getTime(),
      message: form.message.value,
      type: this.data.type
    });    
    this.dialogRef.close();
  }

}
