import { Component, OnInit, Input } from '@angular/core';
import { MAX_PRICE, MIN_PRICE } from 'src/app/data/data';
@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {
  @Input() range: any;
  public slide1 = MIN_PRICE;
  public slide2 = MIN_PRICE;
  public minRange = MIN_PRICE;
  public maxRange = MAX_PRICE;

  constructor() { }

  ngOnInit() {    
  }
  slideChange() {
    this.range.min = this.slide1;
    this.range.max = this.slide2;
    if ( this.range.min > this.range.max ) {
    const tmp = this.range.max;
    this.range.max = this.range.min;
    this.range.min = tmp;
  }
  }
}

