<div class="ss-alert">
    <div *ngIf="data.cancel; else infoModal">
        <p class="ss-size-24 ss-bold ss-heebo ss-color-dark">{{data.tittle}}</p>
        <p class="ss-size-16 ss-open-sans ss-color-dark">{{data.body}}</p>
        <div>
            <button mat-dialog-close class="ss-orange-btn ss-btn-text">{{data.cancel}}</button>
            <button class="ss-profile-btn" (click)="save()">{{data.accept}}</button>
        </div>
    </div>
    <ng-template #infoModal>
        <div class="ss-column ss-align-ic ss-info">
            <p class="ss-size-24 ss-bold ss-heebo ss-color-dark">{{data.tittle}}</p>
            <p class="ss-size-16 ss-open-sans ss-color-dark">{{data.body}}</p>
            <img src="{{data.img}}" alt="">
            <button class="ss-orange-btn ss-btn-text" (click)="save()">{{data.accept}}</button>
        </div>
    </ng-template>
</div>