import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import { AngularFireStorage } from 'angularfire2/storage';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class AdditionalInformationService {
  constructor(
    public db: AngularFirestore,
    private storage: AngularFireStorage,
  ) { }

  public  getAdditionalInformation(doc) {
    return this.db.collection('additionalInformation').doc(doc).get().pipe(
      map(data => {
        return data.data();
      })
    );
  }

  public  getTerms(doc) {
    return this.db.collection('additionalInformation').doc(doc).get().pipe(
      map(data => {
        return data.data();
      })
    );
  }

}
