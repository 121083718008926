import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { BlogService } from 'src/app/services/blog.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public isAuthRoute = true;
  public mainPosts = [];
  constructor(
    private router: Router,
    private location: Location,
    private blog: BlogService,
  ) {
    router.events.subscribe(() => {
      this.isAuthRoute = (location.path().includes('/auth'));
    });
  }
  ngOnInit() {
    this.getMainPosts();
  }

  getMainPosts() {
    this.blog.getMainPosts().subscribe(data => {
      this.mainPosts = data;
    });
  }

  goToDetails(id) {
    this.router.navigate(['blog/post/' + id]);
  }

  goToTerms(){
    this.router.navigate(['terms']);
  }
  goToPrivacy(){
    this.router.navigate(['privacy']);
  }


}
