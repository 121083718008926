import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import { AngularFireStorage } from 'angularfire2/storage';
import { HttpClient } from '@angular/common/http';
import { POSTS_PER_PAGE } from 'src/app/data/data';
import { map, concatMap, take, toArray, finalize, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
@Injectable({
    providedIn: 'root'
})
export class BlogService {
    private urlService = environment.backURL + '/posts/list';
    constructor(
        public db: AngularFirestore,
        private storage: AngularFireStorage,
        private http: HttpClient
    ) { }

    public getMainPosts() {
        return this.db.collection('blog', ref => ref.orderBy('date').limit(2)).snapshotChanges().pipe(
            map(posts => {
                const post = posts.map((post: any) => {
                    const data = post.payload.doc.data();
                    const image = data.image !== '' ? data.image : 'assets/imgs/no-image.svg';
                    return ({
                        id: post.payload.doc.id,
                        image: image,
                        title: data.title
                    });
                })
                return post;
            })
        );
    }

    getPosts() {
        return this.db.collection('blog').get().pipe(
            concatMap(x => x.docs),
            map((auction: any) => {
                const data = auction.data();
                const id = auction.id;
                return { id, ...data };
            }),
            toArray()).toPromise();
    }

    getPostById(id) {
        return this.db.collection('blog').doc(id).valueChanges().pipe(
            map((data: any) => {
                const image = data.image !== '' ? data.image : 'assets/imgs/no-image.svg';
                return ({ ...data, image: image })
            })
        );
    }

}

