import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { NAME_REGEX, CARD_REGEX, MONTHS, CODE_REGEX } from 'src/app/data/data';
import { MembershipService } from 'src/app/services/membership.service';
import { OpenPayService } from 'src/app/services/open-pay.service';
import { ToastService } from 'src/app/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { SubSink } from 'subsink';


@Component({
  selector: 'app-card-form',
  templateUrl: './card-form.component.html',
  styleUrls: ['./card-form.component.scss']
})
export class CardFormComponent implements OnInit {
  public CARD_REGEX = CARD_REGEX;
  private subs = new SubSink();
  public years = [];
  public dataForm: FormGroup;
  public card = "";
  public isCodeShown = false;
  public months = MONTHS;
  private language: any;

  constructor(
    public dialogRef: MatDialogRef<CardFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private membership: MembershipService,
    private openPay: OpenPayService,
    private toast: ToastService,
    private translate: TranslateService

  ) { }

  ngOnInit() {
    this.subs.add(
      this.translate.get('MEMBERSHIP').subscribe(data => {
        this.language = data;
      })
    )
    const currentYear = (new Date()).getFullYear();
    for (let i = 1; i < 6; i++) {
      this.years.push((currentYear + i));
    }
    this.dataForm = this.fb.group({
      completeName: ['', [Validators.required, Validators.pattern(NAME_REGEX)]],
      cardNumber: ['', [Validators.pattern(CARD_REGEX)]],
      month: [[Validators.required]],
      year: [[Validators.required]],
      code: [, [Validators.required, Validators.pattern(CODE_REGEX)]],
      check: [, [Validators.required]],
      maskNumber: []
    });

  }
  showCode() {
    this.isCodeShown = !this.isCodeShown;
  }
  close(val?) {
    this.dialogRef.close(val);
  }

  async registerCard() {
    try {
      this.spinner.show();
      const form = this.dataForm.controls;
      const card = {
        card_number: this.card.replace(/-/g,''),
        holder_name: form.completeName.value,
        expiration_year: form.year.value.toString().substr(-2),
        expiration_month: form.month.value,
        cvv2: form.code.value,
      };
      const dataCard: any = await this.openPay.createCardToken(card);
      const cardClientRequest = {
        clientId: this.data.userID,
        cardRequest: {
          token_id: dataCard.token,
          device_session_id: dataCard.session
        }
      };
      await this.membership.addCardToClient(cardClientRequest);
      this.spinner.hide();
      this.close(1);
    } catch (err) {
      this.toast.show(this.language.errAddCard);
      this.spinner.hide();
      this.close();
    }

  }
  ngOnDestroy() {
    this.subs.unsubscribe();
  }


}
