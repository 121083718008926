<div class="ss-form-modal-main" *ngIf="!isInfoSaved; else infoSaved">
    <button (click)="close()" class="ss-form-modal-close">
        <mat-icon svgIcon="ic-search-close"></mat-icon>
    </button>
    <form [formGroup]="dataForm" class="ss-auth-form ss-open-sans ss-color-brown-grey">
        <p class="ss-form-modal-title ss-color-dark ss-heebo ss-bold">{{'CONTACT.looksLike' | translate}}</p>
        <mat-form-field>
            <label class="ss-size-10">{{'CONTACT.whatsYourName' | translate}}</label>
            <input formControlName="completeName" name="completeName" matInput>
            <label class="ss-size-10"
                *ngIf="dataForm.get('completeName').hasError('required') && (dataForm.controls.completeName.dirty || dataForm.controls.completeName.touched)">
                {{'GENERAL.dataRequired' | translate}}
            </label>
            <label class="ss-size-10"
                *ngIf="dataForm.get('completeName').hasError('pattern')">{{'GENERAL.nameError' | translate}}</label>
        </mat-form-field>
        <mat-form-field>
            <label class="ss-size-10">{{'GENERAL.email' | translate}}</label>
            <input formControlName="email" name="email" matInput (input)="validateForm()">
            <label class="ss-size-10"
                *ngIf="dataForm.get('email').hasError('required') && (dataForm.controls.email.dirty || dataForm.controls.email.touched)">
                {{'GENERAL.dataRequired' | translate}}
            </label>
            <label class="ss-size-10"
                *ngIf="dataForm.get('email').hasError('pattern')">{{'GENERAL.emailError' | translate}}</label>
            <label class="ss-size-10"
                *ngIf="dataForm.get('email').hasError('alreadyExists')">{{'AUTH.SIGN-UP.emailAlreadyExists' | translate}}</label>
        </mat-form-field>
        <mat-form-field>
            <label class="ss-size-10">{{'CONTACT.phone' | translate}}</label>
            <input formControlName="cellphone" name="cellphone" matInput type="tel" [maxlength]="PHONE_LENGTH"
                (input)="validateForm()">
            <label class="ss-size-10"
                *ngIf="dataForm.get('cellphone').hasError('pattern')">{{'PROFILE.phoneError' | translate}}</label>
            <label class="ss-size-10"
                *ngIf="dataForm.get('cellphone').hasError('required') && (dataForm.controls.cellphone.dirty || dataForm.controls.cellphone.touched)">
                {{'GENERAL.dataRequired' | translate}}
            </label>
        </mat-form-field>
        <button class="ss-orange-btn ss-btn-text ss-form-modal-btn" [class.disabled]="!dataForm.valid"
            [disabled]="!dataForm.valid" (click)="sendInfo()">
            {{'CONTACT.sendMyData' | translate}}
        </button>
    </form>
</div>
<ng-template #infoSaved>
    <div class="ss-column ss-form-data-sent">
        <p class="ss-form-sent-title ss-color-dark ss-heebo ss-bold">{{'CONTACT.dataSent' | translate}}</p>
        <p class="ss-size-16 ss-color-dark">{{'CONTACT.weWillContactYou' | translate}}</p>
        <img src="assets/icons/mail.svg">
        <button class="ss-center-button ss-orange-btn ss-btn-text ss-form-modal-btn"
            (click)="close()">{{'GENERAL.getIt' | translate}}</button>
    </div>
</ng-template>

<ngx-spinner bdColor="#333" size="large" color="#fc9200" type="ball-beat" [fullScreen]="true"></ngx-spinner>