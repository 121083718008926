<div class="ss-row-between ss-details-head">
    <p class="ss-color-black ss-size-22 ss-heebo ss-bold">{{'PAYMENTS.details' | translate}}</p>
    <button (click)="close()">
        <mat-icon svgIcon="ic-search-close"></mat-icon>
    </button>
</div>

<div class="ss-row-between ss-details-row">
    <p class="ss-color-gunmetal ss-size-16 ss-heebo ss-bold">{{'PAYMENTS_HISTORY.paymentDate' | translate}}</p>
    <p class="ss-color-gunmetal ss-size-14 ss-open-sans">{{data.operation_date | datePipe:"complete"}}</p>
</div>
<div class="ss-row-between ss-details-row">
    <p class="ss-color-gunmetal ss-size-16 ss-heebo ss-bold">{{'PAYMENTS.period' | translate}}</p>
    <p class="ss-color-gunmetal ss-size-14 ss-open-sans">
        {{data.operation_date | datePipe:"day-month"}} - {{nextPeriod | datePipe:"day-month"}}</p>
</div>
<div class="ss-row-between ss-details-row">
    <p class="ss-color-gunmetal ss-size-16 ss-heebo ss-bold">{{'PAYMENTS.membership' | translate}}</p>
    <p class="ss-color-gunmetal ss-size-14 ss-open-sans">{{data.type}}</p>
</div>
<div class="ss-row-between ss-details-row">
    <p class="ss-color-gunmetal ss-size-16 ss-heebo ss-bold">{{'PAYMENTS_HISTORY.amount' | translate}}</p>
    <p class="ss-color-gunmetal ss-size-14 ss-open-sans">{{data.amount | currency}}</p>
</div>
<div class="ss-row-between ss-details-row">
    <p class="ss-color-gunmetal ss-size-16 ss-heebo ss-bold">{{'PAYMENTS.paymentMethod' | translate}}</p>
    <p class="ss-color-gunmetal ss-size-14 ss-open-sans">{{'PAYMENTS.card' | translate}}(####...{{data.card.card_number.substr(-4)}})</p>
</div>