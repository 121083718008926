<div class="ss-footer-container" *ngIf="!isAuthRoute">
  <div class="ss-footer-container-img">
    <img src="assets/imgs/subastas-logo.svg" alt="">
  </div>
  <div class="ss-footer-sections">
    <div class="ss-footer-contact">
      <p class="ss-footer-title-option">{{'FOOTER.contact' | translate}}</p>
      <!-- <div class="ss-row ss-footer-options">
        <img src="assets/imgs/icon-location-withe.svg" alt="">
        <p class="ss-size-13 ss-color-dark ss-open-sans">
          {{'FOOTER.address' | translate}}
        </p>
      </div> -->
      <div class="ss-row ss-footer-options">
        <img src="assets/imgs/icon-clock-withe.svg" alt="">
        <p class="ss-size-13 ss-color-dark ss-open-sans">
          {{'FOOTER.schedules' | translate}}
        </p>
      </div>
      <!-- <div class="ss-row ss-footer-options">
        <img src="assets/imgs/icon-phone-white.svg" alt="">
        <p class="ss-size-13 ss-color-dark ss-open-sans">
          <span>
            <a class="ss-color-dark ss-url-style" href="tel:+52555-291-6894 ">
              {{'FOOTER.phone' | translate}}
            </a>
          </span>
        </p>
      </div> -->
      <div class="ss-row ss-footer-options">
        <img src="assets/imgs/icon-email-white.svg" alt="">
        <p class="ss-size-13 ss-color-dark ss-open-sans" [ngStyle]="{'word-break': 'break-all'}">
          <span>
            <a class="ss-color-dark ss-url-style" href="mailto:contacto@sisubastasinmobiliarias.com">
              {{'FOOTER.email' | translate}}
            </a>
          </span>
        </p>
      </div>
    </div>
    <div class="ss-footer-blog">
      <p class="ss-footer-title-option" [routerLink]="['/blog']">{{'FOOTER.blog' | translate}}</p>
      <div *ngFor="let post of mainPosts">
        <div class="ss-row ss-footer-options" (click)="goToDetails(post.id)">
          <div class="ss-footer-post" [ngStyle]="{'background-image': 'url('+post.image+')'}"></div>
          <p class="ss-size-13 ss-color-dark ss-open-sans">
            {{post.title}}
          </p>
        </div>
      </div>
    </div>
    <div class="ss-footer-company">
      <p class="ss-footer-title-option ">{{'FOOTER.company' | translate}}</p>
      <p class="ss-size-13 ss-color-dark ss-open-sans" [routerLink]="['/we-are']">
        {{'FOOTER.aboutUs' | translate}}
      </p>
      <p class="ss-size-13 ss-color-dark ss-open-sans" [routerLink]="['/how']">
        {{'FOOTER.ourServices' | translate}}
      </p>
      <p class="ss-size-13 ss-color-dark ss-open-sans" [routerLink]="['/faqs']">
        {{'FOOTER.faq' | translate}}
      </p>
      <p class="ss-size-13 ss-color-dark ss-open-sans" [routerLink]="['/terms']">
        {{'GENERAL.conditionsAndTerms' | translate}}
      </p>
    </div>
    <div class="ss-footer-follow-us ss-column">
      <p class="ss-footer-title-option">{{'FOOTER.followUs' | translate}}</p>
      <div class="ss-row">
        <button>
          <a href="https://www.linkedin.com/company/si-subastas-inmobiliarias" target="_blank">
            <mat-icon svgIcon="ic-linkedin"></mat-icon>
          </a>
        </button>
        <button>
          <a href="https://twitter.com/SubastasI" target="_blank">
            <mat-icon svgIcon="ic-twitter"></mat-icon>
          </a>
        </button>
        <button>
          <a href="https://www.facebook.com/sisubastasinmobiliarias" target="_blank">
            <mat-icon svgIcon="ic-facebook"></mat-icon>
          </a>
        </button>
        <button>
          <a href="https://www.instagram.com/subastas_inmobiliarias/" target="_blank">
            <img class="ic-instagram" src="\assets\imgs\instagram.svg" alt="">
          </a>
        </button>
      </div>
      <div class="ss-column ss-payments-through">
        <p class="ss-footer-title-option">{{'FOOTER.paymentsThrough' | translate}}</p>
        <img class="ss-img-payments" src="\assets\imgs\logo_openpay.png" alt="">
      </div>
    </div>
  </div>
</div>
<div class="ss-size-13 ss-open-sans ss-color-gunmetal ss-footer-text ss-bg-light-blue">
  <div>
    <span class="ss-footer-auctions">{{'FOOTER.auctions'| translate}}</span>
    {{'FOOTER.rightsReserved' | translate}} <span class="ss-color-light-grey"> | </span>
    {{'FOOTER.yesItReserves' | translate}} <span class="ss-color-light-grey"> | </span>
    <button class="ss-size-13 ss-open-sans ss-color-gunmetal" (click)="goToTerms()">
      {{'GENERAL.conditionsAndTerms' | translate}}<span class="ss-color-light-grey ss-pipe"> | </span>
    </button>
  </div>
  <div>
    <button class="ss-size-13 ss-open-sans ss-color-gunmetal" (click)="goToPrivacy()">
      {{'GENERAL.privacy' | translate}} <span class="ss-color-light-grey"> | </span>
    </button>
    {{'FOOTER.developedBy' | translate}}
    <span class="ss-semi-bold">{{'FOOTER.bambu' | translate}}</span>
  </div>
  <div>
    <span class="ss-color-light-grey"> | </span>
    {{'FOOTER.version' | translate}}
  </div>
</div>