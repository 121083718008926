<div class="terms-section">
    <p class="ss-semi-bold ss-size-22 ">{{privacy?.title}}</p>
    <div *ngFor="let paragraph of privacy?.body; index as i">
        <p *ngIf="i === 1 || i === 3 || i === 7;else noTitle" class="ss-semi-bold ss-title-margin">
            <br>
            {{paragraph}}
        </p>
        <ng-template #noTitle>
            <p>
                {{paragraph}}
            </p>
        </ng-template>
    </div>
</div>