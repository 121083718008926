import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/services/auth-guard.service';
import { ActionComponent } from 'src/app/action/action.component';
import { TermsQuestionsComponent } from 'src/app/terms-questions/terms-questions.component';
const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('src/app/auth/auth.module').then(m => m.AuthModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'action',
    component: ActionComponent
  },
  {
    path: 'terms',
    component: TermsQuestionsComponent
  },
  {
    path: 'privacy',
    component: TermsQuestionsComponent
  },
  {
    path: 'faqs',
    component: TermsQuestionsComponent
  },
  {
    path: 'profile',
    loadChildren: () => import('src/app/profile/profile.module').then(m => m.ProfileModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'oportunities',
    loadChildren: () => import('src/app/properties/properties.module').then(m => m.PropertiesModule)
  },
  {
    path: '',
    loadChildren: () => import('src/app/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'blog',
    loadChildren: () => import('src/app/blog/blog.module').then(m => m.BlogModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
