import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ACTION_MODE } from 'src/app/data/data';
import { AuthService } from '../services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PASSWORD_REGEX, PASS_MAX_LENGTH, PASS_MIN_LENGTH } from 'src/app/data/data';
import { SubSink } from 'subsink';
declare let fbq: Function;

@Component({
  selector: 'app-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss']
})
export class ActionComponent implements OnInit, OnDestroy {
  public language: any;
  public MODE = ACTION_MODE;
  public mode: string;
  public isExpiredCode = false;
  public verify = {
    title: '',
    body: ''
  };
  private subs = new SubSink();
  public dataForm: FormGroup;
  public passwordsMatch = true;
  public isPasswordShown = false;
  public isConfirmShown = false;
  public isChanged = true;
  public oobCode: String;

  constructor(
    private route: ActivatedRoute,
    private auth: AuthService,
    private translate: TranslateService,
    private router: Router,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.subs.add(
      this.translate.get('ACTION').subscribe(res => {
        this.language = res;
      }),
      this.route.queryParams.subscribe(
        query => {
          this.mode = query.mode;
          this.action(query.mode, query.oobCode);
        },
      )
    );
    this.dataForm = this.fb.group({
      password: ['', [
        Validators.required,
        Validators.pattern(PASSWORD_REGEX),
        Validators.maxLength(PASS_MAX_LENGTH),
        Validators.minLength(PASS_MIN_LENGTH)
      ]],
      confirmPass: ['', [Validators.required]],
      terms: []
    });
  }

  public action(mode, code) {
    switch (mode) {
      case ACTION_MODE.RESET: {
        this.oobCode = code;
        break;
      }
      case ACTION_MODE.VERIFY: {
        this.requestVerifyEmail(code);
        break;
      }
    }
  }

  public requestVerifyEmail(code) {
    this.auth.verifyEmail(code).then(resp => {
      this.isExpiredCode = false;
      this.verify.title = this.language.accountVerified;
      this.verify.body = this.language.thanks;
    }, error => {
      this.isExpiredCode = true;
      this.verify.title = this.language.expiredCode;
      this.verify.body = this.language.requestAnotherCode;
    });
  }

  public goToLogin() {
    fbq('track', 'CompleteRegistration');

    this.router.navigate(['/auth/login']);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  public checkPassword(value: string) {
    if (value === this.dataForm.get('confirmPass').value) {
      this.passwordsMatch = true;
      this.dataForm.get('confirmPass').setErrors(null);
    } else if (value !== this.dataForm.get('confirmPass').value && this.dataForm.get('confirmPass').value.length > 0) {
      this.passwordsMatch = false;
      this.dataForm.get('confirmPass').setErrors({ 'incorrect': true });
    }
  }



  public checkConfirmPass(value: string) {
    if (value === this.dataForm.get('password').value) {
      this.passwordsMatch = true;
      this.dataForm.get('confirmPass').setErrors(null);
    } else {
      this.dataForm.get('confirmPass').setErrors({ 'incorrect': true });
      this.passwordsMatch = false;
    }
  }

  public requestResetPassword() {
    this.isChanged = !this.isChanged;
    this.auth.newPassword(this.oobCode, this.dataForm.get('password').value);
  }
  public showPassword() {
    this.isConfirmShown = !this.isConfirmShown;
  }
}
