<div class="ss-open-sans ss-contact-form">
    <div class="ss-row ss-contact-head">
        <p class="ss-size-24 ss-bold ss-heebo ss-contact-title">
            {{'CONTACT.contact' | translate}}
        </p>
        <button (click)="close()">
            <mat-icon svgIcon="ic-search-close"></mat-icon>
        </button>
    </div>
    <form [formGroup]="dataForm">
        <mat-form-field>
            <label class="ss-size-10">{{'CONTACT.whatsYourName' | translate}}</label>
            <input formControlName="completeName" name="completeName" matInput>
            <label class="ss-size-10"
                *ngIf="dataForm.get('completeName').hasError('required') && (dataForm.controls.completeName.dirty || dataForm.controls.completeName.touched)">
                {{'GENERAL.dataRequired' | translate}}
            </label>
            <label class="ss-size-10"
                *ngIf="dataForm.get('completeName').hasError('pattern')">{{'GENERAL.nameError' | translate}}</label>
        </mat-form-field>
        <mat-form-field>
            <label class="ss-size-10">{{'GENERAL.email' | translate}}</label>
            <input formControlName="email" name="email" matInput>
            <label class="ss-size-10"
                *ngIf="dataForm.get('email').hasError('required') && (dataForm.controls.email.dirty || dataForm.controls.email.touched)">
                {{'GENERAL.dataRequired' | translate}}
            </label>
            <label class="ss-size-10"
                *ngIf="dataForm.get('email').hasError('pattern')">{{'GENERAL.emailError' | translate}}</label>
            <label class="ss-size-10"
                *ngIf="dataForm.get('email').hasError('alreadyExists')">{{'AUTH.SIGN-UP.emailAlreadyExists' | translate}}</label>
        </mat-form-field>
        <mat-form-field >
                <label class="ss-size-10">{{'CONTACT.message' | translate}}</label>
            <textarea matInput formControlName="message" name="message"></textarea>
                <label class="ss-size-10"
                    *ngIf="dataForm.get('message').hasError('required') && (dataForm.controls.message.dirty || dataForm.controls.message.touched)">
                    {{'GENERAL.dataRequired' | translate}}
                </label>
        </mat-form-field>
        <button class="ss-orange-btn ss-btn-text ss-form-modal-btn" [class.disabled]="!dataForm.valid"
            [disabled]="!dataForm.valid" (click)="sendInfo()">
            {{'CONTACT.sendMyData' | translate}}
        </button>
    </form>
</div>
<ngx-spinner bdColor = "#333" size = "large" color = "#fc9200" type = "ball-beat" [fullScreen] = "true"></ngx-spinner>
