<div class="ss-images-close">
    <button (click)="close()" class="ss-color-white ss-open-sans ss-size-13">
        <img src="assets/icons/icon-arrown-next-gris-copy.svg" alt="">
        <span>
                {{name}}
        </span>
    </button>
</div>
<div class="">
    <button (click)="getPrevDoc()" class="ss-images-prev">
        <img src="assets/icons/icon-arrown-next-gris-copy.svg" alt="">
    </button>

    <img src="{{url}}" alt="" *ngIf="data.documents[index].type !== 'pdf'; else pdfView">
    <ng-template #pdfView>
        <object [data]="url" type="application/pdf"></object>
    </ng-template>
    <button (click)="getNextDoc()" class="ss-images-next">
        <img src="assets/icons/icon-arrown-next-gris.svg" alt="">
    </button>
</div>

<div class="ss-image-controllers-section">
    <div class="ss-image-controllers ss-row ss-open-sans">
        <div>
            Página {{page}} de {{docs.length}}
        </div>
        <div>
            <button>-</button>
            <button>+</button>
        </div>
    </div>
</div>