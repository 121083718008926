import { Component, OnInit } from '@angular/core';
import { FaqService } from '../services/faq.service'
import { SubSink } from 'subsink';


@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent implements OnInit {
  public panelOpenState = false;
  private subs = new SubSink();
  public tempFaqs = new Array(7);
  public faqs = [];
  constructor(
    private faqService: FaqService,
  ) { }

  ngOnInit() {
    this.getFaqs()
  }

  public getFaqs() {
    this.subs.add(
      this.faqService.getFaqs().subscribe(resp => {
        this.faqs = resp;
      })
    )
  }

  public changePanelOpenState(value){
    this.panelOpenState = value
  }
}
