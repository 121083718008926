<div class="ss-landing-menu" *ngIf="isHomeRoute; else authMenu">
    <nav class="navbar navbar-expand-lg navbar-light ss-heebo ss-size-14">
        <form [formGroup]="dataForm" class="ss-row-between ss-bg-white ss-search-form" *ngIf="isSearchingMode">
            <div class="ss-row">
                <mat-icon svgIcon="ic-arrow-left" (click)="searchMode()"></mat-icon>
                <input formControlName="search" matInput class="ss-color-dark ss-size-14 ss-open-sans"
                    (keyup.enter)="searchAuctions()">
            </div>
            <mat-icon svgIcon="ic-search" (click)="searchAuctions()" *ngIf="!isSearched; else deleteSearch"></mat-icon>
            <ng-template #deleteSearch>
                <mat-icon svgIcon="ic-search-close" (click)="deleteSearched()"></mat-icon>
            </ng-template>
        </form>
        <a class="navbar-brand" [routerLink]="['/']">
            <img src="assets/imgs/subastas-logo.svg">
        </a>
        <div>
            <button class="ss-search-mobile" (click)="searchMode()">
                <mat-icon svgIcon="ic-search"></mat-icon>
            </button>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <img src="assets/icons/menu.svg" alt="">
            </button>
        </div>

        <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav ml-auto">
                <div *ngIf="!isLogged; else userLogged" class="ss-not-logged">
                    <li class="nav-item ss-bg-orange ss-size-14 ss-color-white ss-500 ss-heebo" data-toggle="collapse"
                        data-target="#navbarNav">
                        <p class="ss-joinus">{{'LAYOUT.joinUs' | translate}}</p>
                    </li>
                    <li class="nav-item ss-terms-link" data-toggle="collapse" data-target="#navbarNav"
                        [routerLink]="['oportunities/membership-type']">
                        <button class="ss-color-orange ss-bold">{{'LAYOUT.tryMe' | translate}}</button>
                    </li>
                    <li class="nav-item" data-toggle="collapse" data-target="#navbarNav" [routerLink]="['/auth/login']">
                        <button class="ss-color-orange ss-bold">{{'LAYOUT.login' | translate}}</button>
                    </li>
                </div>
                <ng-template #userLogged>
                    <div class="ss-not-logged">
                        <li class="nav-item ss-bg-orange ss-size-20 ss-color-white" data-toggle="collapse"
                            data-target="#navbarNav">
                            {{user?.name.split(' ')[0]}}
                        </li>
                        <li class="nav-item" routerLinkActive="ss-nav-item-active" data-toggle="collapse"
                            data-target="#navbarNav" [routerLink]="['profile']">
                            <a class="nav-link">{{'LAYOUT.myProfile' | translate}}</a>
                        </li>
                    </div>
                </ng-template>
                <li class="nav-item" routerLinkActive="ss-nav-item-active" data-toggle="collapse"
                    data-target="#navbarNav" [routerLink]="['/oportunities']">
                    <a class="nav-link ss-terms-link" routerLinkActive="ss-nav-link-active ss-terms-link">{{'LAYOUT.oportunities' | translate}}</a>
                </li>
                <li class="nav-item" routerLinkActive="ss-nav-item-active" data-toggle="collapse"
                    data-target="#navbarNav" [routerLink]="['/how']">
                    <a class="nav-link ss-terms-link" routerLinkActive="ss-nav-link-active ss-terms-link">{{'LAYOUT.howWork' | translate}}</a>
                </li>
                <li class="nav-item" routerLinkActive="ss-nav-item-active" data-toggle="collapse"
                    data-target="#navbarNav" [routerLink]="['/we-are']">
                    <a class="nav-link ss-terms-link" routerLinkActive="ss-nav-link-active ss-terms-link">{{'LAYOUT.whoAreWe' | translate}}</a>
                </li>
                <li *ngIf="isLogged" class="nav-item ss-logut-item">
                    <button class="nav-link ss-terms-link" (click)="logout()">
                        <img src="assets/icons/logout.svg" alt="">
                        {{'LAYOUT.logout' | translate}}
                    </button>
                </li>
                <div *ngIf="!isLogged; else userName" class="ss-nav-auth">
                    <button class="ss-access ss-color-gunmetal"
                        [routerLink]="['/auth/login']">{{'LAYOUT.login' | translate}}</button>
                    <button class="ss-orange-btn ss-taste ss-terms-link"
                        [routerLink]="['oportunities/membership-type']">{{'LAYOUT.tryMe' | translate}}</button>
                </div>
                <ng-template #userName>
                    <div class="ss-nav-auth">
                        <button (click)="showAuthOptions()" class="ss-row">
                            <span class="ss-color-gunmetal ss-size-14">{{user?.name.split(' ')[0]}}</span>
                            <div class="ss-nav-user-photo {{membershipOptions.class}}"
                                [ngStyle]="{'background-image': user?.photo !== '' ? 'url('+user?.photo+')' : 'url(assets/icons/avatar.svg)'}">
                            </div>
                        </button>
                        <div *ngIf="authOptionsShown" class="ss-bg-white ss-column-between ss-auth-options">
                            <button class="ss-size-13 ss-color-black ss-open-sans" [routerLink]="['profile']"
                                (click)="showAuthOptions()">{{'LAYOUT.myProfile' | translate}}</button>
                            <button (click)="logout()" class="ss-color-black ss-size-13 ss-open-sans">
                                <img src="assets/icons/logout.svg">
                                {{'LAYOUT.logout' | translate}}
                            </button>
                        </div>
                    </div>
                </ng-template>

            </ul>
        </div>
    </nav>
</div>
<ng-template #authMenu>
    <div class="ss-auth-menu">
        <button [routerLink]="['/']">
            <img src="assets/imgs/subastas-logo.svg">
        </button>
    </div>
</ng-template>