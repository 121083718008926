<div *ngIf="mode === MODE.RESET; else verifyAccount">
  <div class="ss-general-container ss-reset-password-container" *ngIf="isChanged; else passwordChanged">
      <div class="ss-reset-password-section">
        <div class="ss-reset-password-container-head">
          <p class="ss-size-24 ss-color-dark ss-heebo ss-bold">{{'AUTH.RESET-PASSWORD.resetPassword' | translate}}</p>
          <p class="ss-size-14 ss-color-dark ss-open-sans">{{'AUTH.RESET-PASSWORD.dataToResetPassword' | translate}}</p>

        </div>
        <form [formGroup]="dataForm" class="ss-auth-form-reset-password ss-open-sans ss-color-brown-grey">
          <mat-form-field>
            <label class="ss-size-10">{{'AUTH.RESET-PASSWORD.newPassword' | translate}}</label>
            <input formControlName="password" name="password" matInput type="{{!isPasswordShown? 'password':'text'}}"
              (input)="checkPassword($event.target.value)">
            <mat-icon svgIcon="{{ isPasswordShown ? 'ic-pass-un-on':'ic-pass-lock-off'}}" (click)="isPasswordShown = !isPasswordShown"></mat-icon>
            <label class="ss-size-10" *ngIf="dataForm.get('password').hasError('required') && (dataForm.controls.password.dirty || dataForm.controls.password.touched)">
              {{'GENERAL.dataRequired' | translate}}
            </label>
            <label class="ss-size-10" *ngIf="dataForm.get('password').hasError('pattern') || dataForm.get('password').hasError('minlength') || dataForm.get('password').hasError('maxlength')">{{'GENERAL.passError'
              | translate}}</label>
          </mat-form-field>
          <mat-form-field>
            <label class="ss-size-10">{{'GENERAL.confirmPass' | translate}}</label>
            <input formControlName="confirmPass" name="confirmPass" matInput (input)="checkConfirmPass($event.target.value)"
              type="{{!isConfirmShown? 'password':'text'}}">
            <mat-icon svgIcon="{{ isConfirmShown ? 'ic-pass-un-on':'ic-pass-lock-off'}}" (click)="showPassword()"></mat-icon>
            <label class="ss-size-10 ss-input-error" *ngIf="!passwordsMatch">{{'GENERAL.passwordNotMatch' | translate}}</label>
          </mat-form-field>

          <button class="ss-bg-orange ss-color-pearl ss-size-14 ss-bold ss-heebo {{!dataForm.valid ? 'disabled' : ''}}"
            [disabled]="!dataForm.valid" (click)="requestResetPassword()">
            {{'GENERAL.continue' | translate}}
          </button>
        </form>
      </div>
  </div>
  <ng-template #passwordChanged>
    <div class="ss-general-container ss-login-change-success ss-open-sans">
      <div class="ss-form-head-new-password">
        <p class="ss-size-24 ss-bold">{{'AUTH.NEW-PASSWORD.passwordChanged' | translate}}</p>
        <p class="ss-size-16">{{'AUTH.NEW-PASSWORD.changeSucces' | translate}}</p>
        <img src="assets/imgs/mail.svg" alt="">
        <button class="ss-bg-orange ss-color-pearl ss-size-14 ss-bold ss-heebo" [routerLink]="['/']">
          {{'GENERAL.getIt' | translate}}
        </button>
      </div>
    </div>

  </ng-template>
</div>
<ng-template #verifyAccount>
  <div class="ss-bg-white ss-general-container ss-email-verified ss-column">
    <p class="ss-size-24 ss-bold ss-heebo ss-verified-title">{{verify.title}}</p>
    <p class="ss-size-16 ss-open-sans">{{verify.body}}</p>
      <img src="assets/icons/ilust-suscription.svg" alt="">
      <button class="ss-bg-orange ss-btn-text"
        (click)="goToLogin()">{{'AUTH.LOGIN.login' | translate}}</button>
  </div>
</ng-template>