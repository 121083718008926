import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SEARCH_REGEX } from 'src/app/data/data';
import { AuctionService } from 'src/app/services/auction.service';
import { SubSink } from 'subsink';


@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit, OnDestroy {
  private subs = new SubSink();
  public dataForm: FormGroup;
  public isSearched = false;
  public isHomeRoute = true;
  public isLogged = true;
  public user: any;
  public authOptionsShown = false;
  public isSearchingMode = false;
  public membershipOptions = {};
  public collapse = true;
  constructor(
    private router: Router,
    private location: Location,
    private auth: AuthService,
    private userService: UserService,
    private fb: FormBuilder,
    private auctions: AuctionService
  ) {
    router.events.subscribe(() => {
      this.isHomeRoute = (!location.path().includes('/auth/') && !location.path().includes('/action'));
    });
  }

  ngOnInit() {
    this.dataForm = this.fb.group({
      search: ['', [Validators.required, Validators.pattern(SEARCH_REGEX)]]
    });
    this.subs.add(this.auth.observerCurrentUser.subscribe(user => {
      const accountVerified = user !== null ?
      ((user.emailVerified && user.providerData[0].providerId === 'password') || user.providerData[0].providerId !== 'password')
      : false;
      if (accountVerified) {
        this.authOptionsShown = false;
        this.userService.getUser(user.uid).subscribe(resp => {          
          this.user = resp;
          if (this.user.membership ) {
            this.membershipOptions = this.userService.getMembershipColor(this.user.membership);
          }
        });
        this.isLogged = true;
      } else {
        this.isLogged = false;
      }
    }));
  }

  public logout() {
    this.auth.logout();
  }

  public showAuthOptions() {
    this.authOptionsShown = !this.authOptionsShown;
  }

  searchAuctions() {
    if (this.dataForm.valid) {
      const form = this.dataForm.controls;
      this.isSearched = true;
      if (this.location.path() === '/oportunities' || this.location.path().includes('/oportunities?')) {
        this.auctions.setSearch = form.search.value;
      } else {
        this.router.navigate(['oportunities'], { queryParams: { search: form.search.value } });
      }
    }
  }
  deleteSearched() {
    if (this.location.path() === '/oportunities' || this.location.path().includes('/oportunities?')) {
      this.auctions.setSearch = '';
    }
    this.isSearched = false;
    this.dataForm.reset();

  }

  public searchMode() {
    this.isSearchingMode = !this.isSearchingMode;
  }

  public changeCollapse(){
    this.collapse = !this.collapse;
  }


  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
