<div class="ss-open-sans ss-bottom-light-grey">
    <p class="ss-color-dark ss-semi-bold ss-faqs-subtitle">{{'TERMS.haveQuestions' | translate}}</p>
    <mat-expansion-panel (opened)="changePanelOpenState(true)" (closed)="changePanelOpenState(false)" *ngFor="let faq of faqs">
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{faq?.title}}
            </mat-panel-title>
        </mat-expansion-panel-header>
        <p *ngFor="let paragraph of faq?.body">
            {{paragraph}}
        </p>
    </mat-expansion-panel>
</div>