import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PHONE_REGEX, NAME_REGEX, EMAIL_REGEX } from 'src/app/data/data';
import { AuthService } from 'src/app/services/auth.service';
import { SubSink } from 'subsink';
import { UserService } from 'src/app/services/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MailsService } from 'src/app/services/mails.service';
declare let fbq: Function;

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormModalComponent implements OnInit {
  private subs = new SubSink();
  public dataForm: FormGroup;
  public type: any;
  public isInfoSaved = false;
  public body: any;
  constructor(
    private auth: AuthService,
    private userService: UserService,
    public dialogRef: MatDialogRef<FormModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private mailService: MailsService,
  ) {
    this.type = data.type;
  }
  ngOnInit() {
    this.dataForm = this.fb.group({
      completeName: ['', [Validators.required, Validators.pattern(NAME_REGEX)]],
      email: ['', [Validators.required, Validators.pattern(EMAIL_REGEX)]],
      cellphone: [, [Validators.required, Validators.pattern(PHONE_REGEX)]]
    });
    if (this.auth.currentUser !== null) {
      this.subs.add(this.userService.getContactInfo(this.auth.currentUser.uid).subscribe((info: any) => {
        this.requestSendInfo(info);
      })
      );
    }
  }
  async close() {
    try {
      if (this.data.auctionId && this.data.views) {
        let resp = await this.mailService.buyAuction({
          email: this.body.email,
          name: this.body.name,
          phone: this.body.phone,
          date: this.body.date.getTime(),
          id: this.data.auctionId,
          views: this.data.views
        });
      }
      if (this.data.type === 'Contacto') {
        fbq('track', 'Contact');

        let resp = await this.mailService.emailContact({
          email: this.body.email,
          name: this.body.name,
          phone: this.body.phone,
          date: this.body.date.getTime(),
          type: this.type
        });
      }
    } catch (err) {
    }
    this.dialogRef.close();
  }

  sendInfo() {
    const form = this.dataForm.controls;
    const info = {
      name: form.completeName.value,
      email: form.email.value,
      phone: form.cellphone.value
    };
    this.requestSendInfo(info);
  }

  async requestSendInfo(data) {
    this.spinner.show();
    data.type = this.type;
    data.date = new Date();
    this.body = data;
    try {
      await this.userService.addContactRequest(data);
      this.isInfoSaved = true;
      this.spinner.hide();
    } catch (err) {
      this.isInfoSaved = false;
      this.spinner.hide();
    }
  }


  validateForm() {
    if (this.dataForm.controls.cellphone.value && this.dataForm.controls.cellphone.status === 'VALID' && this.dataForm.controls.email.status === 'INVALID') {
      this.dataForm.get('email').setErrors(null);
    } else if (this.dataForm.controls.email.value && this.dataForm.controls.cellphone.status === 'INVALID' && this.dataForm.controls.email.status === 'VALID') {
      this.dataForm.get('cellphone').setErrors(null);
    }
  }

}
