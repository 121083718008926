import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.scss']
})
export class PaymentDetailsComponent implements OnInit {
  public nextPeriod: any;
  constructor(
    public dialogRef: MatDialogRef<PaymentDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.getPeriodPayment();
  }

  getPeriodPayment() {
    this.nextPeriod = new Date(this.data.operation_date);
    this.nextPeriod.setDate(this.nextPeriod.getMonth() + 1);
  }

  close() {
    this.dialogRef.close();
  }

}
