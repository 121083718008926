import { Pipe, PipeTransform } from '@angular/core';
import { MONTHS } from 'src/app/data/data';
@Pipe({
  name: 'datePipe'
})

export class DatePipe implements PipeTransform {
  private MONTHS = MONTHS;
  public language: any;

  constructor() { }

  transform(value: any, args?: any): any {
    let date: Object;
    if (typeof(value) !== 'string') {
      date = this.transformDate(value);
    } else {

      date = this.transformStringToDate(value);
    }
    switch (args) {
      case 'complete': {
        return this.completeDate(date);
      }
      case 'longFormat': {
        return this.longDate(date);
      }
      case 'day-month': {
        return this.dayMonth(date);
      }
      case 'abrevMonth': {
        return this.abrevMonth(date);
      }
    }
  }

  private transformStringToDate(value) {
    let date = new Date(value);
    date.setDate(date.getDate() + 1);
    return date;
  }

  private transformDate(value) {
    return new Date(value * 1000);
  }

  public longDate(date) {
    return date.getDate() + ' de ' + this.MONTHS[date.getMonth()].name + ' de ' + date.getFullYear();
  }

  public dayMonth(date) {
    return date.getDate() + '/' + this.MONTHS[date.getMonth()].name;
  }

  public completeDate(date) {
    return date.getDate() + ' / ' + this.MONTHS[date.getMonth()].name + ' / ' + date.getFullYear();
  }

  public abrevMonth(date) {
    return date.getDate() + ' '+ (this.MONTHS[date.getMonth()].name).substr(0,3) + ', ' + date.getFullYear();
  }
}
