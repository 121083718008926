import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, take, concatMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MailsService {
  private urlService = environment.backURL;

  constructor(
    private http: HttpClient
  ) { }

  public buyAuction(body) {
    const url = this.urlService + '/emails/buy ';
    return this.http.post(url, body).toPromise();
  }

  public emailContact(body) {
    const url = this.urlService + '/emails/contact ';
    return this.http.post(url, body).toPromise();
  }

  public rejectedMembership(body) {
    const url = this.urlService + '/emails/rejected ';
    return this.http.post(url, body).toPromise();
  }
}

