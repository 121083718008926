import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import { AngularFireAuth } from 'angularfire2/auth';
import { UserService } from './user.service';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { Router } from '@angular/router';
@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private authState: any = null;
    private userID: any;
    private user: any;
    constructor(
        public db: AngularFirestore,
        private afAuth: AngularFireAuth,
        private userService: UserService,
        private router: Router
    ) {
        this.afAuth.authState.subscribe(resp => {
            if (resp) {
                this.user = resp;
                this.userID = resp.uid;
            }
            this.authState = resp;
        });
    }
    get authenticated(): boolean {
        return this.authState !== null;
    }

    get getUserID() {
        return this.userID;
    }
    get currentUser(): any {
        return this.authenticated ? this.authState : null;
    }
    get observerCurrentUser() {
        return this.afAuth.authState;
    }
    // Get credentials
    public getCredentials(email, password) {
        return firebase.auth.EmailAuthProvider.credential(email, password);
    }
    public async requestChangePass(credentials, newPass) {
        try {
            await this.user.reauthenticateWithCredential(credentials);
            const resp = await this.user.updatePassword(newPass);
            return true;
        } catch (error) {
            return false;
        }
    }
    // Verify email
    public verifyEmail(oob: string) {
        return this.afAuth.auth.applyActionCode(oob);
    }
    // GOOGLE
    public googleSign() {
        const provider = new firebase.auth.GoogleAuthProvider();
        return this.signWithPopUp(provider);
    }

    // FACEBOOK

    public facebookSign() {
        const provider = new firebase.auth.FacebookAuthProvider();
        return this.signWithPopUp(provider);
    }

    // TWITTER
    public twitSign() {
        const provider = new firebase.auth.TwitterAuthProvider();
        return this.signWithPopUp(provider);
    }

    async signWithPopUp(provider) {
        try {
            const credential = await this.afAuth.auth.signInWithPopup(provider);
            if (credential.additionalUserInfo.isNewUser) {
                const newUser = {
                    name: credential.user.displayName,
                    email: credential.user.email,
                    photo: credential.user.photoURL,
                    registeredAt: new Date(),
                    type: 'Comprador',
                    registerBySocialNetwork: true,
                };
                await this.userService.addUser(newUser, credential.user.uid);
            } else {
                this.setLastConection(credential.user.uid, new Date(credential.user.metadata.lastSignInTime));
            }
            return true;
        } catch (err) {
            throw err;
        }
    }
    // LOGIN
    public async login(email, password) {
        try {
            const login = await this.afAuth.auth.signInWithEmailAndPassword(email, password);
            this.setLastConection(login.user.uid, new Date(login.user.metadata.lastSignInTime));
            return login;

        } catch (err) {
            return err;
        }
    }
    // SET LASTCONECTION
    public setLastConection(id, lastConection) {
        this.db.collection('user').doc(id).update({ lastConection: lastConection })
    }

    // LOGOUT
    public async logout() {
        await this.afAuth.auth.signOut();
        this.router.navigate(['']);
    }

    // EMAIL-NEW-PASSWORD
    public async resetPassword(email) {
        try {
            const resetPassword = await this.afAuth.auth.sendPasswordResetEmail(email);
            return resetPassword;

        } catch (err) {
            return err;
        }
    }

    // New Password
    public async newPassword(code, newPassword) {
        try {
            const changed = await this.afAuth.auth.confirmPasswordReset(code, newPassword);
            return changed;

        } catch (err) {
            throw err;
        }

    }
    private addUser(user: any, id) {
        return this.db.collection('user').doc(id).set(user);
    }
    // REGISTER
    public async createUser(form: any, password) {
        const user = {
            name: form.completeName.value,
            email: form.email.value,
            photo: '',
            registeredAt: new Date(),
            verifiedEmail: false,
            type: 'Comprador'
        };
        try {
            const result = await this.afAuth.auth.createUserWithEmailAndPassword(user.email, password);
            await this.userService.addUser(user, result.user.uid);
            await this.sendEmailVerification(result.user);
            return result;
        } catch (err) { throw err; }
    }

    private async sendEmailVerification(user) {
        try {
            const result = await user.sendEmailVerification();
            return result;
        } catch (err) { throw err; }
    }

    public sendVerificationMail() {
        return this.afAuth.auth.currentUser.sendEmailVerification();
    }
}
