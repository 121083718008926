<div class="ss-card-main">
    <div class="ss-row-between ss-card-head">
        <p class="ss-color-black ss-bold ss-heebo ss-size-22">{{'PAYMENTS.addCard' | translate}}</p>
        <button (click)="close()">
            <mat-icon svgIcon="ic-search-close"></mat-icon>
        </button>
    </div>
    <form [formGroup]="dataForm" class="ss-open-sans ss-color-brown-grey ss-card-form" autocomplete="off">
        <mat-form-field>
            <label class="ss-size-10">{{'PAYMENTS.cardOwner' | translate}}</label>
            <input autocomplete="false" formControlName="completeName" name="completeName" matInput>
            <label class="ss-size-10"
                *ngIf="dataForm.get('completeName').hasError('required') && (dataForm.controls.completeName.dirty || dataForm.controls.completeName.touched)">
                {{'GENERAL.dataRequired' | translate}}
            </label>
            <label class="ss-size-10"
                *ngIf="dataForm.get('completeName').hasError('pattern')">{{'GENERAL.nameError' | translate}}</label>
        </mat-form-field>
        <mat-form-field>
            <label class="ss-size-10">{{'MEMBERSHIP.cardNumber' | translate}}</label>
            <input matInput maxlength="19" [ngModel]="card | cardPipe" #cardModel="ngModel" [ngModelOptions]="{standalone: true}"  (ngModelChange)="card = $event" name="card" type="tel" pattern="{{CARD_REGEX}}"/>
            <label class="ss-size-10"
                *ngIf="cardModel.invalid">{{'PROFILE.phoneError' | translate}}</label>
        </mat-form-field>
        <p class="ss-color-gunmetal ss-size-14">{{'MEMBERSHIP.expirationDate' | translate}}</p>
        <div class="ss-row ss-form-two-inputs">
            <mat-form-field class="ss-bg-white">
                <mat-select formControlName="month" name="month" placeholder="{{'MEMBERSHIP.month' | translate}}">
                    <mat-option *ngFor="let month of months" [value]="month.number">
                        {{month.cardVal}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="ss-bg-white">
                <mat-select formControlName="year" name="year" placeholder="{{'MEMBERSHIP.year' | translate}}">
                    <mat-option *ngFor="let year of years" [value]="year">
                        {{year}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="ss-form-code">
            <mat-form-field>
                <label class="ss-size-10">{{'MEMBERSHIP.securityCode' | translate}}</label>
                <input autocomplete="false" readonly onfocus="this.removeAttribute('readonly');" formControlName="code"
                    name="code" matInput type="{{isCodeShown ? 'text':'password'}}" maxlength="3">
                <mat-icon svgIcon="{{ isCodeShown ? 'ic-pass-un-on':'ic-pass-lock-off'}}" (click)="showCode()">
                </mat-icon>
                <label class="ss-size-10"
                    *ngIf="dataForm.get('code').hasError('code') && (dataForm.controls.code.dirty || dataForm.controls.code.touched)">
                    {{'GENERAL.dataRequired' | translate}}
                </label>
                <label class="ss-size-10"
                    *ngIf="dataForm.get('code').hasError('pattern')">{{'MEMBERSHIP.codeError' | translate}}</label>
            </mat-form-field>
        </div>
        <div class="ss-row ss-form-check">
            <mat-checkbox formControlName="check"></mat-checkbox>
            <p class="ss-size-13 ss-colo-brown-grey">{{'MEMBERSHIP.dataTerms' | translate}}</p>
        </div>
        <div class="ss-card-form-submit">
            <button class="ss-bg-orange ss-btn-text ss-form-data-button" [class.disabled]="!dataForm.valid || card.length === 0 || cardModel.invalid"
                [disabled]="!dataForm.valid || card.length === 0 || cardModel.invalid" (click)="registerCard()">
                {{'GENERAL.continue' | translate}}
            </button>
        </div>
    </form>
</div>

<ngx-spinner bdColor="#333" size="large" color="#fc9200" type="ball-beat" [fullScreen]="true"></ngx-spinner>