import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return new Observable<boolean>(observer => {
      this.authService.observerCurrentUser.subscribe((auth) => {
        const accountVerified = auth !== null ? (auth.emailVerified && auth.providerData[0].providerId === 'password') : false;
        if (state.url.includes('/profile') && accountVerified) {
          observer.next(true);
        } else if (accountVerified) {
          observer.next(false);
          this.router.navigate(['']);
        } else if (state.url.includes('/profile') && auth === null) {
          observer.next(false);
          this.router.navigate(['']);
        } else {
          observer.next(true);
        }
      });
    });
  }
}
