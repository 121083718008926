import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import { AngularFireStorage } from 'angularfire2/storage';

import { MEMBERSHIPS, MEMBERSHIP_STATUS} from 'src/app/data/data';
import { map } from 'rxjs/operators';
@Injectable({
    providedIn: 'root'
})
export class UserService {
    private userID = '';
    constructor(
        public db: AngularFirestore,
        private storage: AngularFireStorage
    ) { }
    public addUser(user: any, id) {
        return this.db.collection('user').doc(id).set(user);
    }

    public getUser(id) {        
        return this.db.collection('user').doc(id).valueChanges();
    }

    public getContactInfo(id) {
        return this.db.collection('user').doc(id).get().pipe(
            map(data => {
                const contactInfo = {
                    name: data.data().name,
                    email: data.data().email,
                    phone: (data.data().pones && data.data().pones.cellphone) ? data.data().pones.cellphone : '',
                    ife: data.data().idCard,
                    paymentID: data.data().paymentSystemId
                };
                return contactInfo;
            })
        );
    }

    public verifyEmail(userID) {
        return this.db.collection('user').doc(userID).update({ verifiedEmail: true });
    }

    public addMembershipInfo(userID, membership, clientID) {
        return this.db.collection('user').doc(userID).update({ paymentSystemId: clientID, membership: membership });
    }

    public updateMembreship(userID, membership) {
        return this.db.collection('user').doc(userID).update({membership: membership});
    }

    public addContactRequest(data) {        
        return this.db.collection('contactForm').add(data);
    }

    public editUser(user, id) {
        return this.db.collection('user').doc(id).update(user);
    }

    public async editPlrofilePhoto(photo, userID) {
        try {
            const path = 'user/' + userID;
            const response = await this.storage.ref(path).putString(photo, 'data_url');
            const url = await response.ref.getDownloadURL();
            return url;
        } catch (error) {
            throw error;
        }
    }

    public updateIne(ine, id) {
        return this.db.collection('user').doc(id).update({ idCard: ine });
    }

    public async uploadPhoto(name, photo, idUser, dir?) {
        try {
            const path = 'Users/' + idUser + '/' + (dir ? dir : '') + name;
            const resp = await this.storage.ref(path).putString(photo, 'data_url');
            const url = await resp.ref.getDownloadURL();
            return url;
        } catch (err) { throw err; }

    }

    public updateInterest(id, interest) {
        const info = {
            houses: interest.houses.value !== null ? interest.houses.value : false,
            offices: interest.offices.value !== null ? interest.offices.value : false,
            lands: interest.lands.value !== null ? interest.lands.value : false,
            flats: interest.flats.value !== null ? interest.flats.value : false,
            developments: interest.developments.value !== null ? interest.developments.value : false,
            zc: interest.zc.value !== null ? interest.zc.value : ''
        };
        return this.db.collection('user').doc(id).update({ interest: info });
    }

    public getMembershipColor(membership?) {
        if (membership.status === MEMBERSHIP_STATUS.accepted) {
            switch (membership.type) {
                case MEMBERSHIPS.month.value: {
                    return ({ icon: 'ic-crown', class: 'ss-month' });
                }
                case MEMBERSHIPS.year.value: {
                    return ({ icon: 'ic-diamond', class: 'ss-year' });
                }
                default: {
                    return ({ icon: '', class: 'ss-no-membership-icon' });
                }
            }
        } else {
            return ({ icon: '', class: 'ss-no-membership-icon' });
        }
    }
}

