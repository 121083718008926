import { Injectable } from '@angular/core';
declare var OpenPay: any;
@Injectable({
    providedIn: 'root'
})
export class OpenPayService {
    constructor() { }

    createCardToken(card) {
        // ambiente de pruebas
        OpenPay.setSandboxMode(false);
        OpenPay.setId('mhhyngtwflvhf57ukovo');
        OpenPay.setApiKey('pk_680da8de7cf44be3923f974a0e22865f');
        const deviceSessionId = OpenPay.deviceData.setup('', 'deviceIdHiddenFieldName');
        return new Promise((resolve, reject) => {
            OpenPay.token.create(card, (res => {
                resolve({
                    session: deviceSessionId,
                    token: res.data.id
                });
            }), (err => {
                reject(err);
            }));
        });
    }

}

