<app-nav class="ss-bottom-light-grey"></app-nav>
<div id="ss-app-main-content" class="ss-app-main-content ss-column-between" #ssMainContent (scroll)="showButton($event)">
    <router-outlet (activate)="onActivate()"></router-outlet>
    <app-footer class="ss-top-light-grey"></app-footer>
</div>
<div class="ss-button-to-top animated  {{(position > 100) ? 'fadeIn' : 'fadeOut'}}">
        <button (click)="scrollToTop()">
            <img src="assets/icons/icon-up.svg" alt="">
        </button>
</div>
<ngx-spinner bdColor = "#333" size = "large" color = "#fc9200" type = "ball-beat" [fullScreen] = "true"></ngx-spinner>
