export class AuctionModel {
    id: string;
    propertyType: string;
    totalArea: number;
    appraisalPrice: number;
    address: any;
    publishDate: any;
    views: number;
    imagePrincipal: string;
    constructor(id, data) {
        this.id = id;
        this.propertyType = data.propertyType;
        this.totalArea = data.details.totalArea;
        this.appraisalPrice = data.appraisalPrice;
        this.address = data.address.borough + ', ' + data.address.state;
        this.publishDate = data.publishDate;
        this.views = data.views;
        this.imagePrincipal = data.imagePrincipal;
    }
}
