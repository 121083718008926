<div *ngIf="auction && type">
    <div class="ss-card ss-open-sans {{'ss-card-'+type}}" [ngStyle]="{'background-image': 'url('+image+')'}">
        <div class="ss-card-property-type" (click)="goToAuctionDetails()">
        </div>
        <div class="ss-row-between ss-card-area ss-bg-area">
            <p class="{{propertyClass}} ss-color-white ss-size-15 ss-semi-bold">{{auction.propertyType}}</p>
            <p class=" ss-color-white ss-size-15">{{auction.totalArea | number}} m2</p>
        </div>
        <div class="ss-bg-white ss-card-info">
            <p class="ss-color-gunmetal ss-size-15 ss-heebo ss-500">{{auction.appraisalPrice | currency}}</p>
            <p class="ss-black ss-size-13">{{auction.address}}</p>
            <button class="ss-size-14" (click)="goToAuctionDetails()">{{'AUCTIONS.details' | translate}}</button>
        </div>
    </div>
</div>
