import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-images',
  templateUrl: './images.component.html',
  styleUrls: ['./images.component.scss']
})
export class ImagesComponent implements OnInit {
  public index: number;
  public name: string;
  public url: any;
  public page: number;
  public docs: any;
  public someURL: any
  constructor(
    public dialogRef: MatDialogRef<ImagesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.docs = this.data.documents;
    this.index = this.data.index;
    this.getData(this.index);
  }

  close() {
    this.dialogRef.close();
  }


  getData(index) {
    this.url = this.docs[index].url;
    this.name = this.docs[index].name;
    this.page = index + 1;
  }

  getNextDoc() {
    this.index++;
    if (this.index < this.data.documents.length) {
      this.getData(this.index);
    } else {
      this.index = 0;
      this.getData(this.index);
    }
  }

  getPrevDoc() {
    this.index--;
    if (this.index < 0) {
      this.index = this.docs.length - 1;
      this.getData(this.index);
    } else {
      this.getData(this.index);
    }
  }

}
