<div class="ss-column">
    <div class="range-slider">
        <input [min]="minRange" [max]="maxRange" type="range" [value]="minRange" [(ngModel)]="slide1" (input)="slideChange()">
        <input [min]="minRange" [max]="maxRange" type="range" [value]="minRange" [(ngModel)]="slide2" (input)="slideChange()">
    </div>
    <div class="ss-row-between">
        <span class="ss-color-dark ss-size-14 ss-open-sans">{{range.min | currency}}</span>
        <span class="ss-color-dark ss-size-14 ss-open-sans">{{range.max | currency}}</span>
    </div>
</div>
