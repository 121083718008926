import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss'],
})
export class SlideToggleComponent implements OnInit {
  @Input() options: any;
  @Output() toggleEmitter: EventEmitter<string>;
  constructor() {
    this.toggleEmitter = new EventEmitter();
  }

  ngOnInit() {
    document.getElementsByClassName('mat-slide-toggle-thumb').item(0).innerHTML = this.options.unchecked.name;
  }

  toggleValue(event) {
    const selected = event.checked ? this.options.checked : this.options.unchecked;
    document.getElementsByClassName('mat-slide-toggle-thumb').item(0).innerHTML = selected.name;
    this.toggleEmitter.emit(selected.value);
  }

}
