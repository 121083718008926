import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePipe } from 'src/app/pipes/date.pipe';
import { PricePipe } from 'src/app/pipes/price.pipe';
import { CardPipe } from 'src/app/pipes/card.pipe';
// Translate
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { FormModalComponent } from 'src/app/components/form/form.component';
import { VideoModalComponent } from 'src/app/components/video/video.component';
import { CardComponent } from 'src/app/components/card/card.component';
import { SliderComponent } from 'src/app/components/slider/slider.component';
import { ImagesComponent } from 'src/app/components/images/images.component';
import { BackComponent } from 'src/app/components/back/back.component';
import { SlideToggleComponent } from 'src/app/components/slide-toggle/slide-toggle.component';
import { CardFormComponent } from 'src/app/components/card-form/card-form.component';
import { ContactFormComponent } from 'src/app/components/contact-form/contact-form.component';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { PaymentDetailsComponent } from 'src/app/components/payment-details/payment-details.component';
import { environment } from 'src/environments/environment';

import { NgxSpinnerModule } from 'ngx-spinner';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImageUploadModule } from 'angular2-image-upload';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';



import { AgmCoreModule } from '@agm/core';
import { AgmOverlays } from 'agm-overlays';
import { ShareButtonsModule } from '@ngx-share/buttons';
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    DatePipe,
    CardPipe,
    FormModalComponent,
    CardFormComponent,
    VideoModalComponent,
    CardComponent,
    SliderComponent,
    BackComponent,
    SlideToggleComponent,
    ContactFormComponent,
    PricePipe,
    AlertComponent,
    ImagesComponent,
    PaymentDetailsComponent
  ],
  entryComponents: [
    FormModalComponent,
    VideoModalComponent,
    CardFormComponent,
    ContactFormComponent,
    AlertComponent,
    ImagesComponent,
    PaymentDetailsComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    MatIconModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false
    }),
    RouterModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatTableModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatDialogModule,
    MatSnackBarModule,
    MatRadioModule,
    MatDividerModule,
    AgmOverlays,
    MatExpansionModule,
    NgxSpinnerModule,
    PdfViewerModule,
    AgmCoreModule.forRoot({
      apiKey: environment.gmApiKey
    }),
    ImageUploadModule.forRoot(),
    ShareButtonsModule
  ],
  exports: [
    TranslateModule,
    HttpClientModule,
    RouterModule,
    MatIconModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatCheckboxModule,
    MatSelectModule,
    MatTableModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    DatePipe,
    PricePipe,
    CardPipe,
    ImageUploadModule,
    MatDialogModule,
    FormModalComponent,
    ContactFormComponent,
    CardFormComponent,
    VideoModalComponent,
    AgmCoreModule,
    AgmOverlays,
    CardComponent,
    SliderComponent,
    BackComponent,
    SlideToggleComponent,
    MatRadioModule,
    MatDividerModule,
    MatExpansionModule,
    NgxSpinnerModule,
    AlertComponent,
    ImagesComponent,
    PdfViewerModule,
    ShareButtonsModule
  ]
})
export class SharedModule { }
