export const DEFAULT_LANGUAGE = 'es';
export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PASSWORD_REGEX = '[a-zA-Z0-9]*';
export const SEARCH_REGEX = '[a-zA-Z0-9-ZáéíóúÁÉÍÚÓñÑüÜ ][a-zA-Z0-9-ZáéíóúñÁÉÍÚÓÑüÜ ]*';
export const NAME_REGEX = '[a-zA-ZáéíóúÁÉÍÚÓñÑüÜ ][a-zA-ZáéíóúñÁÉÍÚÓÑüÜ ]{5,75}$';
export const PASS_MAX_LENGTH = 16;
export const PASS_MIN_LENGTH = 8;
export const CODE_REGEX = '^[0-9]{3}';
export const CARD_REGEX = '^[0-9]{4}-[0-9]{4}-[0-9]{4}-[0-9]{4}';
export const PHONE_REGEX = '^[0-9]{8,10}';
export const PHONE_LENGTH = 10;
export const PHONE_HOME_LENGTH = 8;
export const PHONE_MAX_LENGTH = 10;
export const PHONE_MIN_LENGTH = 8;
export const ZC_REGEX = '^[0-9]{5}';
export const ZC_LENGTH = 5;
export const POSTS_PER_PAGE = 15;
export const ACTION_MODE = {
    RESET: 'resetPassword',
    VERIFY: 'verifyEmail'
};
export const SNACKBAR_POSITION = 'top';
export const SNACKBAR_OPTIONS = {
    panelClass: 'ss-snackbar',
    duration: 3000,
    position: 'top'
};
export const MEMBERSHIP = {
    NONE: 1,
    MONTH: 2,
    YEAR: 3
};
export const MONTHS = [
    {name: 'Enero', number: 1, cardVal: '01'},
    {name: 'Febrero', number: 2, cardVal: '02'},
    {name: 'Marzo', number: 3, cardVal: '03'},
    {name: 'Abril', number: 4, cardVal: '04'},
    {name: 'Mayo', number: 5, cardVal: '05'},
    {name: 'Junio', number: 6, cardVal: '06'},
    {name: 'Julio', number: 7, cardVal: '07'},
    {name: 'Agosto', number: 8, cardVal: '08'},
    {name: 'Septiembre', number: 9, cardVal: '09'},
    {name: 'Octubre', number: 10, cardVal: '10'},
    {name: 'Noviembre', number: 11, cardVal: '11'},
    {name: 'Diciembre', number: 12, cardVal: '12'}
];
export const MAP_ZOOM = 15;
export const AUCTION_STATE = {
    ONE: 1,
    TWO: 2,
    THREE: 3
};
export const PROPERTY_TYPE = {
    HOUSE: 'Casa',
    DEVELOPMENT: 'Desarrollo',
    OFFICE: 'Oficina',
    LAND: 'Terreno',
    FLAT: 'Departamento'
};
export const AUCTIONS_PER_PAGE = 15;
export const CAROUSEL_AUCTIONS = 12;
export const UNIT_PRICE = 1000000;
export const MAX_PRICE = 100000000;
export const MIN_PRICE = 600000;
export const RANGE_PRICE = {
    min: 600000,
    max: 600000
};
export const MEMBERSHIP_STATUS = {
    accepted: 'Aceptada',
    pending: 'Pendiente',
    rejected: 'Rechazada',
    renovate: 'Renovar',
    canceled: 'Cancelada'
};
export const MEMBERSHIPS = {
    month: {
        idOpenPay: 'pmmrbg6pqv4ukfeby3jj',
        value: 'Mensual',
        icon: 'ic-crown',
        color: 'month',
        price: 1199,
        text: 'Membresía Mensual',
        invoiced: 'Facturado mensualmente',
        opening: 'Precio por inauguración:',
        discount: 599
    },
    year: {
        idOpenPay: 'pb4n8bkzsyzhlyicb81j',
        value: 'Anual',
        icon: 'ic-diamond',
        color: 'year',
        price: 20000,
        text: 'Membresía Anual',
        invoiced: 'Facturado por 12 meses'
    }
};
