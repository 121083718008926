import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'pricePipe'
})

export class PricePipe implements PipeTransform {
  constructor() {}

  transform(value: any, args?: any): any {
    switch (args) {
      case 'shortPrice': {
        return this.shortPrice(value);
      }
    }
  }

  shortPrice(value) {
        let newAmount = value.split(',');
        newAmount[0] = '$ #';
        newAmount = newAmount.join();
        return newAmount;
  }

}
