import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { SubSink } from 'subsink';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-terms-questions',
  templateUrl: './terms-questions.component.html',
  styleUrls: ['./terms-questions.component.scss']
})
export class TermsQuestionsComponent implements OnInit, OnDestroy {
  private subs = new SubSink();
  public title = '';
  public isTermsInfo = true;
  public isPrivacy = true;
  public isFaq = true;
  public language: any;
  public section = new Subject<string>();
  public selectOptions = [
    { name: 'Términos y condiciones', value: 'terms' },
    { name: 'Aviso de Privacidad', value: 'advice' }
  ];
  constructor(
    private router: Router,
    private location: Location,
    private translate: TranslateService
  ) {
    this.isTermsInfo = (location.path() === '/terms');
    this.isPrivacy = (location.path() === '/privacy');
    this.isFaq = (location.path() === '/faqs');
    this.subs.sink = this.translate.get('TERMS').subscribe(res => {
      if (this.isPrivacy) {
        this.title = res.title;
      } else if (this.isTermsInfo) {
        this.title = res.legalInfoAndTerms;

      } else {
        this.title = res.freqQuestions;
      }
    });
  }

  ngOnInit() {
  }


  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
